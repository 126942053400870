import { parse, stringify } from "qs";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const convertStringsToBooleans = (item: object) => {
  for (const value of Object.values(item)) {
    if (value && typeof value === "object") {
      if (Array.isArray(value) && value.length === 1) {
        if (value[0] === "true") value[0] = true;
        if (value[0] === "false") value[0] = false;
      } else convertStringsToBooleans(value);
    }
  }
};

const parseSearchParameters = (search: string) => {
  const parsedParameters = parse(search, {
    ignoreQueryPrefix: true,
    plainObjects: true,
    strictNullHandling: true,
    allowSparse: true
  });

  convertStringsToBooleans(parsedParameters);
  return parsedParameters;
};

/// *** URL query parameters ***
export function useSearchParameters<T = unknown>() {
  const { search } = useLocation();
  return useMemo(() => parseSearchParameters(search), [search]) as Record<string, T>;
}

export const toQueryParameter = (keyName: string, value: unknown) => {
  return stringify(
    { [keyName]: value },
    {
      addQueryPrefix: true,
      encodeValuesOnly: true,
      arrayFormat: "indices",
      strictNullHandling: true,
      skipNulls: true
    }
  );
};
