import { useContext } from "react";
import { InstrumentInformationsContext } from "./instrument-informations-context/context";
import { DetailsInfoInstruments } from "../log-book-item-cover-sheet-dialog/DetailsInfoInstruments";
import { DetailsInfoBox } from "../log-book-item-cover-sheet-dialog/ItemCoverSheetContent";
import COMMON_LOGS_DATA_MODEL from "../../../utils/constants/commonLogsDataModel";

const DetailsInfoInstrumentsCell = [
  {
    label: COMMON_LOGS_DATA_MODEL.equipmentId.value,
    field: ["equipmentDetails", "equipmentId"]
  },
  {
    label: COMMON_LOGS_DATA_MODEL.serialNumber.value,
    field: ["equipmentDetails", "serialNumber"]
  },
  {
    label: COMMON_LOGS_DATA_MODEL.materialNumber.value,
    field: ["equipmentDetails", "materialNumber"]
  },
  {
    label: COMMON_LOGS_DATA_MODEL.equipmentNickName.value,
    field: ["equipmentDetails", "equipmentNickName"]
  },
  {
    label: COMMON_LOGS_DATA_MODEL.equipmentModel.value,
    field: ["equipmentModel"]
  },
  {
    label: COMMON_LOGS_DATA_MODEL.manufacturer.value,
    field: ["equipmentDetails", "manufacturer"]
  },
  {
    label: COMMON_LOGS_DATA_MODEL.softwareVersion.value,
    field: ["equipmentDetails", "softwareVersion"]
  },
  {
    label: COMMON_LOGS_DATA_MODEL.configuration.value,
    field: ["equipmentDetails", "configurationBaseline"]
  },
  {
    label: COMMON_LOGS_DATA_MODEL.location.value,
    field: ["equipmentDetails", "location"]
  },
  {
    label: COMMON_LOGS_DATA_MODEL.responsiblePerson.value,
    field: ["equipmentDetails", "responsiblePerson"]
  },
  {
    label: COMMON_LOGS_DATA_MODEL.responsibleProxy.value,
    field: ["equipmentDetails", "responsibleProxy"]
  }
];

const InstrumentInformationsContent = () => {
  const { item } = useContext(InstrumentInformationsContext);
  return (
    <DetailsInfoBox data-testid="instrument-informations-content">
      {DetailsInfoInstrumentsCell.map((theItem, index) => {
        const dataSource = item;
        return <DetailsInfoInstruments item={theItem} dataSource={dataSource} index={index} />;
      })}
    </DetailsInfoBox>
  );
};

export default InstrumentInformationsContent;
