//@ts-nocheck
import { OwcCard, OwcTypography, OwcTooltip, OwcIcon } from "@one/react";
import {
  EmptyLinkContainerStyled,
  EmptyLinkContainer,
  LinkContainerDiv,
  LinkContainerDivInnerLeft,
  LinkContainerDivInnerRight,
  LinkContainerStyled
} from "../../log-book-item-form-dialog/FormsStyled";
import { SVGWrapper, generateID } from "@digitallab/grid-common-components";
import { useState } from "react";
import { BoxIcon } from "@digitallab/grid-common-components";
import { useFormikContext } from "formik";
import { ConfirmDialog } from "../../../../components/shared";
import ActionLogLink from "../../ActionLogLink";
import Notify from "../../../notifications/Notify";

type LinkActionsType = "Add" | "Edit" | "Delete";

export const LinksList = () => {
  const { values, setFieldValue } = useFormikContext();
  const [actionLogLinkCurrentFlag, setActionLogLinkCurrentFlag] = useState<LinkActionsType | undefined>();
  const [actionLogLinkName, setActionLogLinkName] = useState();
  const [actionLogLinkUrl, setActionLogLinkUrl] = useState();
  const [actionLogLinkCurrentIndex, setActionLogLinkCurrentIndex] = useState();
  const [addLinkFlag, setAddLinkFlag] = useState();

  const handleActionLinkChange = () => {
    let currentExternalDocment = values?.externalDocument || [];
    switch (actionLogLinkCurrentFlag) {
      case "Add":
        currentExternalDocment.push({
          link: actionLogLinkUrl,
          name: actionLogLinkName
        });
        break;
      case "Edit":
        currentExternalDocment[actionLogLinkCurrentIndex] = {
          link: actionLogLinkUrl,
          name: actionLogLinkName
        };
        break;
      case "Delete":
        currentExternalDocment = values?.externalDocument?.filter((_, index) => index !== actionLogLinkCurrentIndex);
        break;
    }

    setFieldValue("externalDocument", currentExternalDocment, true);
    setActionLogLinkName(null);
    setActionLogLinkUrl(null);
    setAddLinkFlag(false);
    Notify({
      type: "success",
      icon: "circle_confirm",
      appName: "",
      text: `Link ${
        actionLogLinkCurrentFlag === "Delete" ? "deleted" : actionLogLinkCurrentFlag?.toLocaleLowerCase() + "ed"
      } successfully`
    });
    setActionLogLinkCurrentFlag(undefined);
  };

  const openLinkPopup = (action: "Add" | "Edit" | "Delete") => {
    setActionLogLinkCurrentFlag(action);
  };

  return (
    <>
      <OwcCard flat style={{ padding: "0px", width: "100%" }}>
        <div
          slot="header"
          style={{
            display: "flex",
            background: "var(--one-color-background-neutral-2)",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-3px",
            padding: "10px"
          }}
        >
          <OwcTypography
            variant="button"
            id={generateID.customID("AddLinkHeadingBanner", `addLinkHeadingBanner`, "type_title")}
          >
            <strong>Link list</strong>
          </OwcTypography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
          >
            <OwcIcon
              type="legacy"
              variant="primary"
              name="circle_add"
              onClick={() => {
                openLinkPopup("Add");
              }}
            ></OwcIcon>
            <OwcTypography
              variant="subtitle2"
              style={{
                marginLeft: "5px",
                color: "var(--one-color-interaction-default-brand-1)"
              }}
              onClick={() => {
                openLinkPopup("Add");
              }}
            >
              <strong> Add link</strong>
            </OwcTypography>
          </div>
        </div>

        {!values?.externalDocument || values?.externalDocument?.length <= 0 ? (
          <EmptyLinkContainerStyled
            data-testid="not-found-apps"
            id={generateID.customID("EmptyApplication", `application_empty`, "div")}
          >
            <EmptyLinkContainer
              onClick={() => {
                openLinkPopup("Add");
              }}
            >
              <OwcIcon className="emptyIconMyLab">
                <SVGWrapper icon={<BoxIcon />} dimension={90} />
              </OwcIcon>
              <OwcTypography variant="title6" id={generateID.customID("PleaseAddLink", `addLink`, "type_title")}>
                <strong> Please add link</strong>
              </OwcTypography>
              <OwcTypography
                variant="body2"
                id={generateID.customID("NoLinkAvailable", `NoLinkAvailable`, "type_Description")}
              >
                No links available
              </OwcTypography>
            </EmptyLinkContainer>
          </EmptyLinkContainerStyled>
        ) : (
          <LinkContainerStyled data-testid="link-block" id={generateID.customID("LinkBlock", `link_block`, "div")}>
            {values?.externalDocument?.map((item, index) => {
              return (
                <LinkContainerDiv key={index}>
                  <LinkContainerDivInnerLeft>
                    <OwcIcon type="outlined" name="link"></OwcIcon>
                    <OwcTypography
                      variant="subtitle2"
                      style={{
                        marginLeft: "5px",
                        color: "var(--one-color-interaction-default-brand-1)"
                      }}
                    >
                      {item.name}
                    </OwcTypography>
                  </LinkContainerDivInnerLeft>
                  <LinkContainerDivInnerRight>
                    <div
                      style={{ cursor: "pointer", marginRight: "5px" }}
                      onClick={() => {
                        setActionLogLinkName(values?.externalDocument[index]?.name);
                        setActionLogLinkUrl(values?.externalDocument[index]?.link);
                        setActionLogLinkCurrentIndex(index);
                        setAddLinkFlag(false);
                        openLinkPopup("Edit");
                      }}
                    >
                      <OwcIcon
                        name="write"
                        type="filled"
                        id={generateID.customID("LinkBlockEdit", `link_block`, `icon-${index}`)}
                      ></OwcIcon>
                      <OwcTooltip anchor={generateID.customID("LinkBlockEdit", `link_block`, `icon-${index}`)}>
                        {"Edit"}
                      </OwcTooltip>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        openLinkPopup("Delete");
                        setAddLinkFlag(true);
                        setActionLogLinkCurrentIndex(index);
                      }}
                    >
                      <OwcIcon
                        type="outlined"
                        name="delete"
                        id={generateID.customID("LinkBlockDelete", `link_block`, `icon-${index}`)}
                      ></OwcIcon>
                      <OwcTooltip anchor={generateID.customID("LinkBlockDelete", `link_block`, `icon-${index}`)}>
                        {"Delete"}
                      </OwcTooltip>
                    </div>
                  </LinkContainerDivInnerRight>
                </LinkContainerDiv>
              );
            })}
          </LinkContainerStyled>
        )}
      </OwcCard>

      <ConfirmDialog
        close={() => setActionLogLinkCurrentFlag(undefined)}
        approveText={actionLogLinkCurrentFlag === "Edit" ? "Save" : actionLogLinkCurrentFlag}
        open={!!actionLogLinkCurrentFlag}
        onApprove={handleActionLinkChange}
        onCancel={() => {
          setActionLogLinkName(null);
          setActionLogLinkUrl(null);
          setActionLogLinkCurrentFlag(undefined);
          setActionLogLinkCurrentIndex(null);
        }}
        title={`${actionLogLinkCurrentFlag} link`}
        isEnabled={addLinkFlag}
        content={
          <ActionLogLink
            actionLogLinkName={actionLogLinkName}
            setActionLogLinkName={setActionLogLinkName}
            actionLogLinkUrl={actionLogLinkUrl}
            setActionLogLinkUrl={setActionLogLinkUrl}
            actionLogLinkCurrentFlag={actionLogLinkCurrentFlag}
            setAddLinkFlag={setAddLinkFlag}
          />
        }
        disableBackdropClick={true}
      />
    </>
  );
};
