import { GXPemptyInstrumentsOptions } from "../constants";
import { LogStatus } from "../features/log-book/enums";
import { changeDateFormat } from "../features/log-book/helpers";
import { DigitalLabLogbookInstrumentModelType } from "../models/DigitalLabLogbookInstrumentModelType";
import { IActionLogFormValues, IRunLogFormValues, ISelectValues } from "./StepperContainer";
import gql from "graphql-tag";
import { v4 } from "uuid";

export const createRunLogBulkVariables = (
  formValues: IRunLogFormValues,
  equipmentList: Partial<DigitalLabLogbookInstrumentModelType>[],
  user: { site: string; user: string; name: string } | undefined,
  systemStatuses: ISelectValues[]
) => {
  const variables = {
    ...formValues,
    runStartDate:
      formValues.runStartDate instanceof Date ? changeDateFormat(formValues.runStartDate) : formValues.runStartDate!,
    runEndDate:
      formValues.runEndDate instanceof Date ? changeDateFormat(formValues.runEndDate) : formValues.runEndDate!,
    site: user?.site ?? "",
    approverId: user?.user ?? "",
    approverUserName: user?.name ?? ""
  };

  return Object.fromEntries(
    equipmentList.map((equipment, index) => {
      const systemStatusKey = (systemStatuses as ISelectValues[]).find(
        (item) => item.value === equipment.systemStatus
      )?.key;
      return [
        `input${index}`,
        {
          // KEEP ...VARIABLES AT THE TOP OR IT WILL OVERWRITE OTHER VALUES
          ...variables,
          runLogEntryId: v4(),
          inventoryId: equipment.inventoryId ?? "",
          equipmentModel: equipment.equipmentModel ?? "",
          serialNumber: equipment.serialNumber ?? "",
          equipmentId: equipment.equipmentId ?? "",
          modelVersion: equipment.modelVersion ?? "",

          systemStatus: {
            key: systemStatusKey ?? equipment.systemStatus ?? "",
            value: equipment.systemStatus ?? ""
          },
          gxpReady: GXPemptyInstrumentsOptions.qualificationStatus.find(
            (entry: { key: string | undefined }) => entry.key === equipment.qualificationStatus
          ) || { key: "", value: "" }
        }
      ];
    })
  );
};

export const createRunLogBulkMutation = (equipmentList: Partial<DigitalLabLogbookInstrumentModelType>[]) => {
  const mutationString = equipmentList
    .map(
      (_, index) => `
    runLog${index}: createDigitalLabLogbookRunLogChange(input: $input${index}) {
    id
    }
  `
    )
    .join("\n");

  const output = ` mutation BulkRunLogMutations(
        ${equipmentList.map((_, index) => `$input${index}: CreateDigitalLabLogbookRunLogChangeInput!`).join(", ")}
      ) {
        ${mutationString}
      }`;
  return gql(output);
};

export const createActionLogBulkVariables = (
  formValues: IActionLogFormValues,
  equipmentList: Partial<DigitalLabLogbookInstrumentModelType>[],
  user: { site: string; user: string; name: string } | undefined,
  systemStatuses: ISelectValues[]
) => {
  const variables = {
    ...formValues,
    actionDate:
      formValues.actionDate instanceof Date ? changeDateFormat(formValues.actionDate) : formValues.actionDate!,

    site: user?.site ?? "",
    approverId: user?.user ?? "",
    approverUserName: user?.name ?? ""
  };
  const result = Object.fromEntries(
    equipmentList.map((equipment, index) => {
      const systemStatusKey =
        formValues.systemStatus.key === "KEEP_CURRENT_STATUS"
          ? (systemStatuses as ISelectValues[]).find((item) => item.value === equipment.systemStatus)?.key ??
            equipment.systemStatus
          : formValues.systemStatus.key;
      const gxpReadyKey =
        formValues.gxpReady.key === "KEEP_CURRENT_STATUS"
          ? GXPemptyInstrumentsOptions.qualificationStatus.find(
              (entry: { value: string | undefined }) => entry.value === equipment.qualificationStatus
            )?.key
          : formValues.gxpReady.key;
      return [
        `input${index}`,
        {
          // KEEP ...VARIABLES AT THE TOP OR IT WILL OVERWRITE OTHER VALUES
          ...variables,
          logSheetEntryId: v4(),
          inventoryId: equipment.inventoryId ?? "",
          equipmentModel: equipment.equipmentModel ?? "",
          serialNumber: equipment.serialNumber ?? "",
          equipmentId: equipment.equipmentId ?? "",
          modelVersion: equipment.modelVersion ?? "",
          editComment: "",
          editReason: "",
          instrumentDetails: {
            configurationBaseline: equipment.configurationBaseline ?? null,
            instrumentGxPStatus: equipment.instrumentGxPStatus ?? null,
            equipmentNickName: equipment.equipmentNickName ?? null,
            responsiblePerson: equipment.responsiblePerson ?? null,
            manufacturer: equipment.manufacturer ?? null,
            softwareVersion: equipment.softwareVersion ?? null,
            location: equipment.location ?? null,
            responsibleProxy: null
          },

          logStatus: LogStatus.ACTIVE,
          materialNumber: equipment?.materialNumber ?? "",
          operatorId: "",
          operatorUserName: "",
          systemStatus: {
            key: systemStatusKey ?? "",
            value:
              (systemStatuses as ISelectValues[]).find((item) => item.key === systemStatusKey)?.value ??
              equipment.systemStatus ??
              ""
          },
          gxpReady: {
            key: gxpReadyKey ?? "",
            value:
              GXPemptyInstrumentsOptions.qualificationStatus.find((item) => item.key === gxpReadyKey)?.value ??
              equipment.qualificationStatus ??
              ""
          }
        }
      ];
    })
  );
  return result;
};

export const createActionLogBulkMutation = (equipmentList: Partial<DigitalLabLogbookInstrumentModelType>[]) => {
  const mutationString = equipmentList
    .map(
      (_, index) => `
    actionLog${index}: createDigitalLabLogbookLogSheetChange(input: $input${index}) {
    id
    }
  `
    )
    .join("\n");

  const output = ` mutation BulkActionLogMutations(
        ${equipmentList.map((_, index) => `$input${index}: CreateDigitalLabLogbookLogSheetChangeInput!`).join(", ")}
      ) {
        ${mutationString}
      }`;
  return gql(output);
};
